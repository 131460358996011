import { CheckoutCriticalError, ERROR_CODES } from './checkout-critical-errors'
import { renderedPath } from '../Utils/checkout-path'

import { UpdatableItemType } from 'src/Elements/Checkout/V2/types'
import { OrderId, OrderNumber, LineItemId, ProductId, VariantId, PriceId } from 'src/Elements/types'

import {
  ExistingOrderProductCardDetail,
  updateCardByProductIdState,
  UpdatablePccState,
  UpdatableOrder,
  anyUpdatableOrders,
} from '../CheckoutProductSelect/V2/checkoutProductSelect'

type ReactivatableStatus = 'canceled' | 'churned'
type Direction = 'upgrade' | 'switch'
type LineItem = {
  id: LineItemId
  variant_id: VariantId
  price_id: PriceId
  product_id: ProductId
  upgrade_downgrade_options: { direction: Direction; variant_to_id: number; price_to_id: number }[]
  subscription_details?: {
    status?: ReactivatableStatus
    reactivatable?: boolean
  }
}

type Order = { id: OrderId; order_number: OrderNumber; line_items: LineItem[] }

type ExistingOrdersResponse = {
  orders: Order[]
}

type ProductCardDetails = Record<ProductId, ExistingOrderProductCardDetail>
type ProductCardDetailsObj = Record<ProductId, { updatableOrders: Record<OrderId, UpdatableOrder> }>

const REACTAVATABLE_SERVICE_STATUS: ReactivatableStatus[] = ['canceled', 'churned']

export class CheckoutExistingOrders {
  static #hasReactivatableItems = false
  static #hasUpgradeDowngradeItems = false
  static #productCardDetails: ProductCardDetails = {}
  static #productCardDetailsObj: ProductCardDetailsObj = {}
  static orderDetailsByOrderId = {}

  static fetch(): Promise<void> {
    // NOTE: for now we don't wanna deal with upgrades/downgrades and reactivations here
    if (globalThis.globalResourceData.resourceName == 'checkout') return Promise.resolve()

    const orderId = new URLSearchParams(window.location.search).get('manage_order_id')
    const params = orderId ? `?manage_order_id=${orderId}` : ''
    return globalThis
      .CFFetch(
        `${window.location.origin}${renderedPath()}/existing_orders${params}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
        { retries: 3, shouldCaptureServerError: true }
      )
      .then((response) => response.json())
      .then((data: ExistingOrdersResponse) => {
        this.cleanUp()
        this.processExistingOrdersResponse(data)
      })
      .catch((e) => {
        throw new CheckoutCriticalError(ERROR_CODES.FETCH_EXISTING_ORDERS_ERROR, {
          cause: e,
        })
      })
  }

  static cleanUp(): void {
    this.#hasReactivatableItems = false
    this.#hasUpgradeDowngradeItems = false
    this.orderDetailsByOrderId = {}
    this.#productCardDetails = {}
    this.#productCardDetailsObj = {}
    // TODO: remove orders details by product from product card
  }

  static clearUpdatableOrders(selectedProductId: ProductId): void {
    if (anyUpdatableOrders()) {
      globalThis.globalResourceData.products?.forEach(({ id: productId }) => {
        const isSelected = selectedProductId == productId
        updateCardByProductIdState(productId, {
          quantity: isSelected ? 1 : 0,
          renderType: undefined,
          selectedOrderIndex: undefined,
          selectedUpdatableCartItemIndex: undefined,
          updatableOrders: undefined,

          cartItemOrderId: undefined,
          cartItemLineItemId: undefined,
          cartItemRenderType: undefined,
        })
      })
      this.cleanUp()
    }
  }

  static processExistingOrdersResponse(existingOrdersResponse: ExistingOrdersResponse): void {
    existingOrdersResponse.orders.forEach((order) => {
      const orderId = order.id
      this.orderDetailsByOrderId[orderId] = order
      const orderNumber = order.order_number

      order.line_items.forEach((lineItem) => {
        const productId = lineItem.product_id
        const lineItemSubscriptionDetails = lineItem.subscription_details
        if (
          REACTAVATABLE_SERVICE_STATUS.includes(lineItemSubscriptionDetails?.status) &&
          lineItemSubscriptionDetails?.reactivatable
        ) {
          this.processReactivation(lineItem, orderId, orderNumber, productId)
        } else {
          this.processUpgradeDowngrades(lineItem, orderId, orderNumber, productId)
        }
      })
    })

    if (this.#hasReactivatableItems || this.#hasUpgradeDowngradeItems) {
      this.processUpdatableObj()
      this.updateSelectedCardItem()
    }
  }

  static processUpdatableObj(): void {
    this.#productCardDetails = {}
    Object.keys(this.#productCardDetailsObj).forEach((k) => {
      this.#productCardDetails[k] = {
        updatableOrders: Object.values(this.#productCardDetailsObj[k].updatableOrders).reverse(),
      }
    })
  }

  static processReactivation(
    lineItem: LineItem,
    orderId: OrderId,
    orderNumber: OrderNumber,
    productId: ProductId
  ): void {
    const lineItemId = lineItem.id
    const variantId = lineItem.variant_id
    const priceId = lineItem.price_id

    if (!globalThis.Checkout.variantsById[variantId] || !globalThis.Checkout.pricesById[priceId]) {
      return
    }

    this.#hasReactivatableItems = true

    this.storeProductCardDetails(
      globalThis.Checkout.CheckoutStates.REACTIVATE,
      orderId,
      orderNumber,
      lineItemId,
      productId,
      variantId,
      priceId
    )
  }

  static processUpgradeDowngrades(
    lineItem: LineItem,
    orderId: OrderId,
    orderNumber: OrderNumber,
    productId: ProductId
  ): void {
    const lineItemId = lineItem.id

    lineItem.upgrade_downgrade_options.forEach((upgradeDowngrade) => {
      const variantId = upgradeDowngrade.variant_to_id
      const priceId = upgradeDowngrade.price_to_id
      if (!globalThis.Checkout.variantsById[variantId] || !globalThis.Checkout.pricesById[priceId]) {
        return
      }
      this.#hasUpgradeDowngradeItems = true
      this.storeProductCardDetails(
        globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE,
        orderId,
        orderNumber,
        lineItemId,
        productId,
        variantId,
        priceId,
        upgradeDowngrade.direction
      )
    })
  }

  static storeProductCardDetails(
    type: UpdatableItemType,
    orderId: OrderId,
    orderNumber: OrderNumber,
    lineItemId: LineItemId,
    productId: ProductId,
    variantId: VariantId,
    priceId: PriceId,
    direction?: Direction
  ): void {
    this.#productCardDetailsObj[productId] = this.#productCardDetailsObj[productId] ?? {
      updatableOrders: {},
    }

    const updatableOrders = this.#productCardDetailsObj[productId].updatableOrders
    updatableOrders[orderId] =
      updatableOrders[orderId] ??
      ({
        type,
        id: orderId,
        number: orderNumber,
        updatableItems: [],
      } as UpdatableOrder)

    const { name: variantName } = globalThis.Checkout.variantsById[variantId]
    const { name: priceName } = globalThis.Checkout.pricesById[priceId]

    const updatableItems = updatableOrders[orderId].updatableItems
    updatableItems.push({
      variantName,
      priceName: priceName,
      direction: direction ? direction?.charAt(0).toUpperCase() + direction?.slice(1) : null,
      cardDetails: {
        variantId,
        priceId,
        // NOTE: We disable orderId and lineItemId for reactivations until we can perform reactivations
        // on the same order as currently we create a new order when that happens
        ...(type == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE
          ? {
              orderId,
              lineItemId,
            }
          : {}),
      },
    })
    // }
  }

  static updateSelectedCardItem(): void {
    let selectedCartItemDetail: { variantId?: VariantId; productId?: ProductId; priceId?: PriceId }
    globalThis.Checkout.computed.checkoutCart.get().items.find(({ product_id, variant_id, price_id }) => {
      const cardDetails = this.#productCardDetails[product_id]?.updatableOrders[0].updatableItems[0].cardDetails
      if (!globalThis.Checkout.productsById[product_id].bump && this.#productCardDetails[product_id]) {
        selectedCartItemDetail = {}
        selectedCartItemDetail.variantId = cardDetails?.variantId ?? variant_id
        selectedCartItemDetail.priceId = cardDetails?.priceId ?? price_id
        selectedCartItemDetail.productId = product_id
        return true
      }
    })

    if (!selectedCartItemDetail) {
      selectedCartItemDetail = {}
      const product = globalThis.Checkout.products.filter((p) => !p.bump)[0]
      const cardDetails = this.#productCardDetails[product.id]?.updatableOrders[0].updatableItems[0].cardDetails
      const variant = product.variants[0]
      const price = variant.prices[0]

      selectedCartItemDetail.variantId = cardDetails?.variantId ?? Number(variant.id)
      selectedCartItemDetail.priceId = cardDetails?.priceId ?? Number(price.id)
      selectedCartItemDetail.productId = product.id
    }

    globalThis.globalResourceData.products?.forEach(({ id: productId }) => {
      const hasUpdatablePccState = !!this.#productCardDetails[productId]
      if (hasUpdatablePccState) {
        const order = this.#productCardDetails[productId].updatableOrders[0]
        const cartItem = order.updatableItems[0].cardDetails
        const updatablePccState: UpdatablePccState = this.#productCardDetails[productId] ?? {}

        updatablePccState.selectedOrderIndex = 0
        updatablePccState.selectedUpdatableCartItemIndex = 0

        updatablePccState.cartItemOrderId = cartItem.orderId
        updatablePccState.cartItemLineItemId = cartItem.lineItemId
        updatablePccState.cartItemRenderType = updatablePccState.renderType = order.type

        updatablePccState.variantId = cartItem.variantId
        updatablePccState.priceId = cartItem.priceId

        const isProductInCart = selectedCartItemDetail.productId == productId
        if (isProductInCart) {
          updatablePccState.variantId = selectedCartItemDetail.variantId
          updatablePccState.priceId = selectedCartItemDetail.priceId
          updatablePccState.quantity = 1
        }
        updateCardByProductIdState(productId, { ...updatablePccState })
      } else {
        updateCardByProductIdState(productId, { selectType: 'single' })
      }
    })
  }
}
