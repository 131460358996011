

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutOrderDetailsContentV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

undefined


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const order_details = this.order_details ?? {"order_number":"#12345","total_amount":"$197.00","tax_amount":"$0.00","shipping_address":{"street_one":"Robert Robertson, 1234 NW Bobcat Lane","city":"St. Robert","zip":"65584-5678","state":"MO","country":"United States"},"billing_address":{"street_one":"Robert Robertson, 1234 NW Bobcat Lane","city":"St. Robert","zip":"65584-5678","state":"MO","country":"United States"},"billing_payment_method":{"id":1,"pretty_type":"Credit Card","details":"XXXX-XXXX-XXXX-1111","type":"payment-card","icon":"visa"},"created_at":"June 14 at 1:45","line_items":[{"variant_id":25,"product_id":6508,"quantity":1,"amount":"$197.00","display_price":"Free for 14 days then $197/month","variant_name":"ClickFunnels 2.0 (pro)","image":"/editor/editor-demo-image-placeholder.svg"}]} ;

      
      let html = '';
      {
        html += `<div class="elCheckoutOrderDetailsInfoWrapper"><div class="elCheckoutOrderDetailsInfo"><h2>Order ${order_details.order_number}</h2><div class="elCheckoutOrderDetailsStatus" data-order-status="${order_details.service_status}">${order_details.service_status.charAt(0).toUpperCase() + order_details.service_status.slice(1)}</div></div>`
        if (order_details.name) {
          html += `<span class="elCheckoutOrderDetailsName">${order_details.name}</span>`
        }
        html += `<span class="elCheckoutOrderDetailsDate">${order_details.created_at}</span></div><div class="elCheckoutOrderDetailsItemsWrapper elCheckoutOrderDetailsWrapper"><h3>Items</h3><div class="elCheckoutOrderDetailsItems elCheckoutOrderDetailsContainer">`
        const c0 = order_details.line_items
        const fl1 = new CF2ForloopDrop(c0.length)
        for (const line_item of c0) {
          const forloop = fl1
          html += `<div class="elCheckoutOrderDetailsItem">`
          const imageUrl = line_item.image;
          if (imageUrl) {
            html += `<div class="elCheckoutOrderDetailsItemImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
            if (true && !imageUrl && !null && false == false) {
              html += ` forceHide`
            }
            html += `" data-liquid-replace="item">`
            if (imageUrl || !!null) {
              html += `<img class="elImage" src="${imageUrl ?? null}"/>`
            } else if (false) {
              html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
            }
            html += `</div><div class="elCheckoutOrderDetailsItemQty">${line_item.quantity}</div></div>`
          }
          html += `<div class="elCheckoutOrderDetailsItemInfo"><span class="elCheckoutOrderDetailsItemName">${line_item.product_name}</span>`
          if (line_item.display_price) {
            html += `<span class="elCheckoutOrderDetailsItemPrice">${line_item.display_price}</span>`
          }
          if (line_item.variant_properties_values.length > 0) {
            html += `<div class="elCheckoutOrderDetailsVariants">`
            const c2 = line_item.variant_properties_values
            const fl3 = new CF2ForloopDrop(c2.length)
            for (const variant_item of c2) {
              const forloop = fl3
              html += `<span>${variant_item.value}</span>`
              forloop.next();
            }
            html += `</div>`
          }
          html += `</div><div class="elCheckoutOrderDetailsItemAmount"><span>${line_item.amount}</span></div></div>`
          forloop.next();
        }
        html += `</div></div>`
        if (order_details.shipping_address) {
          html += `<div class="elCheckoutOrderDetailsShipping elCheckoutOrderDetailsWrapper"><h3>Shipping Address</h3><div class="elCheckoutOrderDetailsContainer"><i class="fas fa-map-marker-alt"></i><span>${order_details.shipping_address}</span></div></div>`
        }
        if (order_details.billing_address) {
          html += `<div class="elCheckoutOrderDetailsBilling elCheckoutOrderDetailsWrapper"><h3>Billing Address</h3><div class="elCheckoutOrderDetailsContainer"><i class="far fa-credit-card"></i><span>${order_details.billing_address}</span></div></div>`
        }
        html += `<div class="elCheckoutOrderDetailsPayment elCheckoutOrderDetailsWrapper"><h3>Payment Method</h3>`
        if (order_details.billing_payment_method.type == "payment-card") {
          html += `<div class="elCheckoutOrderDetailsPaymentType"><div class="elCheckoutOrderDetailsContainer"><i class="fab fa-cc-${order_details.billing_payment_method.icon}"></i><span>${order_details.billing_payment_method.details}</span></div></div>`
        } else if (order_details.billing_payment_method.type == "paypal") {
          html += `<div class="elCheckoutOrderDetailsPaymentType"><div class="elCheckoutOrderDetailsContainer"><i class="fab fa-paypal"></i><span>${order_details.billing_payment_method.details}</span></div></div>`
        }
        html += `</div><div class="elCheckoutOrderDetailsSummaryWrapper elCheckoutOrderDetailsWrapper"><h3>Summary</h3><div class="elCheckoutOrderDetailsSummary elCheckoutOrderDetailsContainer">`
        if (order_details.tax_amount) {
          html += `<div class="elCheckoutOrderDetailsSummaryTax"><span>Tax</span><span>${order_details.tax_amount}</span></div>`
        }
        if (order_details.shipping_amount) {
          html += `<div class="elCheckoutOrderDetailsSummaryShipping"><span>Shipping</span><span>${order_details.shipping_amount}</span></div>`
        }
        html += `<div class="elCheckoutOrderDetailsSummaryTotal"><span>Total</span><span>${order_details.total_amount}</span></div></div></div>`

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('CheckoutOrderDetailsContent/V1', CheckoutOrderDetailsContentV1)
window["CheckoutOrderDetailsContentV1"] = CheckoutOrderDetailsContentV1

