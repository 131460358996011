import { CartData } from 'src/Elements/Cart/types'
import { CheckoutCart, SummaryLineItem } from '../types'
import { ShippingOption } from './summary'
import { ChangeResolveDetails, LineItem } from '@stripe/stripe-js'
import { CheckoutDataAddress, StripeAddress } from './types'

function hasRecurringPrice(cart: CartData): boolean {
  return cart.items.some(({ price_id }) => {
    const price = globalThis.globalResourceData.pricesById[price_id]
    return price.is_recurring
  })
}

export function hasPhysicalProductsWithParams(cart: CartData): boolean {
  return cart.items.some(({ product_id, variant_id }) => {
    const product = globalThis.Checkout.productsById[product_id]
    const variant = globalThis.Checkout.variantsById[variant_id]
    let productType = variant?.product_type
    productType = productType || product?.product_type
    return productType == 'physical'
  })
}

export function hasUpgradeDowngradeItems(cart: CheckoutCart): boolean {
  return cart.items.some((item) => (item.type as string) === 'upgradeDowngrade')
}

export function canCalculateSubtotalFromCart(cart: CartData): boolean {
  return !hasRecurringPrice(cart)
}

export function getStripeMode(cart: CartData, amount: number): 'subscription' | 'payment' | 'setup' {
  if (amount == 0) return 'setup'

  return hasRecurringPrice(cart) ? 'subscription' : 'payment'
}

export function getCartEstimatedTotal(cart: CartData): number {
  const estimatedAmount = cart.items.reduce((acc, { price_id }) => {
    const price = globalThis.globalResourceData.pricesById[price_id]
    return acc + price.initial_price_cents
  }, 0)
  return estimatedAmount
}

export function convertToStripeAddress(address: CheckoutDataAddress): StripeAddress {
  return {
    line1: address.address_one,
    line2: address.address_two,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    country: address.country,
  }
}

export function convertAddressFromStripe(address: StripeAddress): CheckoutDataAddress {
  return {
    address_one: address.line1,
    address_two: address.line2,
    city: address.city,
    state: address.state,
    country: address.country,
    postal_code: address.postal_code,
  }
}

// NOTE: not sure why we have this second way of address, but it's mostly
// likely it's due to how we implemented for Rebilly respecting its form
export function convertAddressFromSession(address: {
  address?: string
  address_2?: string | null
  city: string
  state: string
  zip: string
  country: string
}): CheckoutDataAddress {
  return {
    address_one: address.address,
    address_two: address.address_2,
    city: address.city,
    state: address.state,
    country: address.country,
    postal_code: address.zip,
  }
}

export function calculateStripeLineItems(
  lineItems: SummaryLineItem[],
  shippingOption?: ShippingOption,
  taxAmount?: number
): LineItem[] {
  const allLineItems = lineItems.map(({ priceCents, description }) => {
    return {
      name: description,
      amount: priceCents,
    }
  })
  if (shippingOption) {
    allLineItems.push({
      name: shippingOption.description,
      amount: shippingOption.amount_cents,
    })
  }
  if (taxAmount) {
    allLineItems.push({
      name: 'Taxes',
      amount: taxAmount,
    })
  }
  return allLineItems
}

export const STRIPE_SKIP_SHIPPING_ID = 'skip it'
export function calculateStripeChangeResolveDetails(
  lineItems: SummaryLineItem[],
  quotesResponse: ShippingOption[],
  shippingOption: ShippingOption,
  taxAmount: number
): ChangeResolveDetails {
  return {
    lineItems: calculateStripeLineItems(lineItems, shippingOption, taxAmount),
    shippingRates: quotesResponse?.map((option) => {
      const { description, amount_cents } = option
      return {
        id: JSON.stringify(option),
        amount: amount_cents,
        displayName: description,
      }
    }) ?? [
      {
        id: STRIPE_SKIP_SHIPPING_ID,
        amount: 0,
        displayName: 'Free',
      },
    ],
  }
}

export function parseCssVars(element: HTMLElement, obj: Record<string, string>): any {
  Object.keys(obj ?? {}).forEach((key) => {
    const value = obj[key]
    if (typeof value === 'object') {
      obj[key] = parseCssVars(element, value)
    } else {
      if (typeof value === 'string' && value.includes('--')) {
        obj[key] = getComputedStyle(element).getPropertyValue(value)
      }
    }
  })
  return obj
}
